<script setup>
const props = defineProps({
    required: {
        type: Boolean,
        default: false,
    }
})
</script>
<template>
    <label class="text-subtitle-2 ml-1 mb-1 d-block">
        <slot></slot> <span v-if="props.required" class="text-error">*</span>
    </label>
</template>